<template>
  <div>
    <!-- handle error -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
    <b-row class="row-eq-height">
      <b-col md="6" class="mb-1">
        <b-card class="h-100 cardH">
          <!-- User Info: Left col -->
          <!-- User Avatar & Action Buttons -->
          <b-row>
            <b-col md="6" c>
              <CoolLightBox
                :items="profileArr"
                :index="imgIndex"
                @close="imgIndex = null"
              >
              </CoolLightBox>
              <b-img
                rounded
                @click="imgIndex = 0"
                :src="userData.profile_picture"
                class="image-fluid"
                :alt="userData.firstname"
                style="max-width: 100%; height: auto;"
              />
            </b-col>
            <b-col md="6">
              <h4 class="mb-1">{{ userData.username }}</h4>
              <h5 class="mb-1">
                <feather-icon
                  icon="MailIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />Email:<span style="color: black;" class="card-text">
                  {{ userData.email }}</span
                >
              </h5>
              <h5 class="mb-1">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />First name:
                <span style="color: black;" class="card-text">
                  {{ userData.firstname }}</span
                >
              </h5>
              <h5 class="mb-1">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />Last Name:
                <span style="color: black;" class="card-text">
                  {{ userData.lastname }}</span
                >
              </h5>
              <h5 class="mb-1">
                <feather-icon
                  icon="CalendarIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />Birthdate:
                <span style="color: black;" class="card-text">
                  {{ userData.birthdate }}</span
                >
              </h5>
              <h5 class="mb-1">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />Adminstration:
                <span style="color: black;" class="card-text">
                  <b-badge variant="primary">{{
                    userData.adminstration
                  }}</b-badge></span
                >
              </h5>
              <h5 class="mb-1">
                <feather-icon
                  icon="FlagIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />Address:<span style="color: black;" class="card-text">
                  {{ userData.address }}</span
                >
              </h5>
              <h5 class="mb-1">
                <feather-icon
                  icon="PhoneIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />Phone:<span style="color: black;" class="card-text">
                  {{ userData.phone }}</span
                >
              </h5>
              <h5 v-if="!showID" class="mb-1">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />ID:<span style="color: black;" class="card-text"
                  ><b-button
                    variant="info"
                    class="ml-2"
                    size="sm"
                    @click="showID = !showID"
                    >Show</b-button
                  ></span
                >
              </h5>
              <h5 v-else class="mb-1">
                <feather-icon
                  icon="UserIcon"
                  class="mr-75"
                  style="color: #0d6efd;"
                />ID:<span style="color: black;" class="card-text"
                  ><b-button
                    variant="info"
                    class="ml-2"
                    size="sm"
                    @click="showID = !showID"
                    >Hide</b-button
                  ></span
                >
              </h5>
            </b-col>
          </b-row>
          <!-- Right Col: Table -->
        </b-card>
      </b-col>
      <b-col md="6" class="mb-1">
        <b-card class="h-100">
          <div v-if="delUser" class="d-flex justify-content-center">
            <b-button
              v-if="authPermissions.includes('update')"
              :to="{ path: '/admin/edite/' + userData.id }"
              variant="primary mr-1 mt-1"
            >
              Edit profile
            </b-button>
            <b-button
              v-if="authPermissions.includes('update')"
              :to="{
                name: 'Admin-Change-Password',
                params: { userid: userData.id, username: userData.username },
              }"
              variant="outline-warning"
              class="mr-1 mt-1"
            >
              change Password
            </b-button>
            <b-button
              v-if="authPermissions.includes('delete')"
              @click="deleteAdmin(userData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Close Account
            </b-button>
          </div>
          <div v-else class="d-flex justify-content-center">
            <b-button
              v-if="authPermissions.includes('update')"
              @click="restoreAdmin(userData.id)"
              variant="outline-danger"
              class="mr-1 mt-1"
            >
              Restore Account
            </b-button>
          </div>
          <hr />
          <div
            v-if="delUser"
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ userData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Updated at
              </h6>
              <h3 class="mb-0">
                {{ userData.updated_at }}
              </h3>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-content-md-center align-items-center text-center"
          >
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Created at
              </h6>
              <h3 class="mb-0">
                {{ userData.created_at }}
              </h3>
            </div>
            <div class="m-4">
              <h6 class="text-muted font-weight-bolder">
                Deleted at
              </h6>
              <h3 class="mb-0">
                {{ userData.deleted_at }}
              </h3>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="showID">
      <b-col md="6" style="max-width: 100%; height: auto;">
        <b-card @click="!toggler" title="ID Front Picture">
          <div class="d-flex justify-content-center">
            <CoolLightBox
              :items="frontArr"
              :index="imgIndex1"
              @close="imgIndex1 = null"
            >
            </CoolLightBox>
            <b-img
              @click="imgIndex1 = 0"
              rounded
              :src="userData.idfront_picture"
              :alt="userData.firstname"
              style="max-width: 100%; height: auto;"
            />
          </div>
        </b-card>
      </b-col>
      <b-col md="6" style="max-width: 100%; height: auto;">
        <b-card title="ID Back Picture">
          <div class="d-flex justify-content-center">
            <CoolLightBox
              :items="backArr"
              :index="imgIndex2"
              @close="imgIndex2 = null"
            >
            </CoolLightBox>
            <b-img
              @click="imgIndex2 = 0"
              rounded
              :src="userData.idback_picture"
              :alt="userData.firstname"
              style="max-width: 100%; height: auto;"
            />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

// import axios from '@/libs/axios'
export default {
  components: {

    CoolLightBox,

  },
  
  data() {
    return {
      showID: false,
      id: 0,
      userData: {},
      errors_back: [],
      showDismissibleAlert: false,
      authPermissions: null,
      delUser: true,
      imgIndex: null,
      imgIndex1: null,
      imgIndex2: null,
      profileArr: [],
      frontArr: [],
      backArr: [],
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.fetchData()
    // fetch data of admin
  },
  created() {
    this.authPermissions = this.$store.state.authPermissions
  },
  methods: {
    fetchData() {
      axios
        .get('admins/' + this.id + '/show')
        .then((result) => {
          this.userData = result.data.data
          if (this.userData.deleted_at != null) {
            this.delUser = false
          } else {
            this.delUser = true
          }
          this.profileArr.push(this.userData.profile_picture)
          this.frontArr.push(this.userData.idfront_picture)
          this.frontArr.push(this.userData.idback_picture)
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // delete Admin Function
    deleteAdmin(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete admin.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete('admins/' + id)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    restoreAdmin(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to restore admin.', {
          title: 'Are You Sure?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .get('admins/' + id + '/restore')
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your work has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.fetchData()
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>

<style scoped>
.cardH {
  margin-bottom: 0px;
}
</style>
